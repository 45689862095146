import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { 
    Typography,
} from 'antd';
import { CaretUpFilled } from '@ant-design/icons';

import { ThirdTypeTableInterface } from './ThirdTypeTable.interface';
import './ThirdTypeTable.css';

function ThirdTypeTable({
    sample,
    title,
    data,
    firtsData
}: ThirdTypeTableInterface) {
    return(
        <div className='GraphInfoContainerSeason'>
            <div className='SampleInfoStyleSeason'>
                <Typography className='TextSampleSeason'>{sample}</Typography>
            </div>
            <div className='TopRaitingContainer'>
                <Typography className='TopRaitingText'>{title}</Typography>
            </div>
            <div className='InfoTopSurvey' key={firtsData.id}>
                <Typography className='RangeTextSeason'>{firtsData.month}</Typography>
                <div className='RowInfo'>
                    <Typography className='TextPercentageSeasonTable'>92%</Typography>
                    <CaretUpFilled style={{color: '#88DD76', fontSize: 12}}/>
                </div>
            </div>
            {data.slice(1).map((isData) => (
                <div className='InfoSurvey' key={isData.id}>
                    <div className='RowInfo'>
                        <div className='IndexRatingStyle'>
                            <Typography className='IndexRatingTextStyle'>{isData.id}</Typography>
                        </div>
                        <Typography className='RangeTextRating'>{isData.month}</Typography>
                    </div>
                    <div className='RowInfo'>
                        <Typography className='TextPercentageRatingTable'>92%</Typography>
                        <CaretUpFilled style={{color: '#88DD76', fontSize: 12}}/>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ThirdTypeTable;