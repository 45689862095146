import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { 
    Typography,
} from 'antd';
import { CaretUpFilled } from '@ant-design/icons';

import { SecondTypeTableInterface } from './SecondTypeTable.interface';
import './SecondTypeTable.css';

function SecondTypeTable({
    sample,
    percentage,
    title,
    data
}: SecondTypeTableInterface) {
    return(
        <div className='GraphInfoContainerTrends'>
            <div className='SampleInfoStyleTrends'>
                <Typography className='TextSampleTrends'>{sample}</Typography>
            </div>
            <Typography className='TitleTable'>{title}</Typography>
            <Typography className='TextPercentageTrends'>{percentage}</Typography>
            {data.map((isData) => (
                <div className='InfoSurvey' key={isData.id}>
                    <div className='RowInfo'>
                        <div className='IndexStyle'>
                            <Typography className='IndexTextStyle'>{isData.id}</Typography>
                        </div>
                        <Typography className='RangeTextTreads'>{isData.month}</Typography>
                    </div>
                    <div className='RowInfo'>
                        <Typography className='TextPercentageTable'>92%</Typography>
                        <CaretUpFilled style={{color: '#88DD76', fontSize: 12}}/>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default SecondTypeTable;