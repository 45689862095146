import React from 'react';

import ThirdTypeTableUI from './ThirdTypeTable';
import { ThirdTypeTableInterface } from './ThirdTypeTable.interface';

function ThirdTypeTable({
    sample,
    title,
    data,
    firtsData
}: ThirdTypeTableInterface) {
    return <ThirdTypeTableUI
        sample={sample}
        title={title}
        data={data}
        firtsData={firtsData}
    />
}

export default ThirdTypeTable;