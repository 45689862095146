import React from 'react';

import SecondTypeTableUI from './SecondTypeTable';
import { SecondTypeTableInterface } from './SecondTypeTable.interface';

function SecondTypeTable({
    sample,
    percentage,
    title,
    data
}: SecondTypeTableInterface) {
    return <SecondTypeTableUI
        sample={sample}
        percentage={percentage}
        title={title}
        data={data}
    />
}

export default SecondTypeTable;