import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import iconDashboard from '../../assets/svg/icon-dashboard.svg';
import iconAnalisis from '../../assets/svg/icon-analisis.svg';
import iconEncuestas from '../../assets/svg/icon-encuesta.svg';
import iconGestionAlertas from '../../assets/svg/icon-gestion-alerta.svg';
import iconEstadisticas from '../../assets/svg/icon-estadisticas.svg';
import iconReportes from '../../assets/svg/icon-reportes.svg';
import iconPlanesAccion from '../../assets/svg/icon-planes-accion.svg';
import iconIA from '../../assets/svg/icon-AI.svg';

import './NavBarBottom.css';

function NavBarBottom() {
    return (
        <nav className="navbar fixed-top navbar-expand-lg text-light background-clientship nav-borders-top nav-borders-bottom second-navbar p-0" style={{ marginTop: "73px" }}>
            <div className="container">
                <div id="sections">
                    <div className="collapse navbar-collapse" id="navbarScroll">
                        <ul className="navbar-nav my-2 my-lg-0">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={iconDashboard} className="icon" /> Dashboard
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/">
                                            <img src={iconAnalisis} className="icon" /> Power Intelligence
                                        </a></li>
                                    <li><a className="dropdown-item" href="encuestas.html">
                                            <img src={iconEncuestas} className="icon" /> Encuestas
                                        </a></li>
                                    <li><a className="dropdown-item" href="alertas.html">
                                            <img src={iconGestionAlertas} className="icon" /> Gestión de Alertas
                                        </a></li>
                                    <li><a className="dropdown-item" href="estadisticas.html">
                                            <img src={iconEstadisticas} className="icon" /> Estadísticas
                                        </a></li>
                                    <li><a className="dropdown-item" href="reportes.html">
                                            <img src={iconReportes} className="icon" /> Reportes
                                        </a></li>
                                    <li><a className="dropdown-item" href="planes.html">
                                            <img src={iconPlanesAccion} className="icon" /> Planes de Acción
                                        </a></li>
                                    <li><a className="dropdown-item" href="motor.html">
                                            <img src={iconIA} className="icon" /> Motor IA
                                        </a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="filter-fixed">
                    <button className="top-data-range" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                        <span id="initial-date">01 ene 2023</span>
                        <span id="final-date">31 ene 2023</span>
                        <span id="filters">Filtros</span>
                    </button>
                </div>
                <div>
                    <div className="mt-3">
                        <p className="tiny-font purple-color"><span id="total-surveys">18,345</span> encuestas respondidas</p>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default NavBarBottom;