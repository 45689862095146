import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { 
    Typography,
    Input,
    Button,
} from 'antd';
import { CaretUpFilled } from '@ant-design/icons';

import { 
    NavBarTop,
    NavBarBottom,
    FirstTypeTable,
    SecondTypeTable,
    ThirdTypeTable
} from '../../components';
import images from '../../assets/images';
import './Dashboard.css';

const data =[
    {
        id: 1,
        month: 'Septiembre',
        suverey: '87,234'
    },
    {
        id: 2,
        month: 'Octubre',
        suverey: '87,234'
    },
    {
        id: 3,
        month: 'Noviembre',
        suverey: '87,234'
    },
    {
        id: 4,
        month: 'Diciembre',
        suverey: '87,234'
    },
    {
        id: 5,
        month: 'Enero',
        suverey: '87,234'
    }
];

const firtsData = data[0];

function Dashboard() {
    return(
        <div>    
            <NavBarTop/>
            <NavBarBottom/>
            <div className='Container-First'>
                <div className='Container-RowTables'>
                    <div className='StyleContainerRow'>
                        <div className='TypeRow'>
                            <Typography className='TitleType'>Encuestas</Typography>
                            <div className='StyleLine'/>
                        </div>
                        <div className='TypeRow'>
                            <FirstTypeTable
                                sample='Muestra: 18,340'
                                percentage='23%'
                                title='Número de encuestas por mes'
                                data={data}
                            />
                            <FirstTypeTable
                                sample='Muestra: 18,340'
                                percentage='23%'
                                title='Número de encuestas por mes'
                                data={data}
                            />
                        </div>
                    </div>
                    <div className='StyleContainerRow'>
                        <div className='TypeRow'>
                            <Typography className='TitleType'>Alertas</Typography>
                            <div className='StyleLine'/>
                        </div>
                        <div className='TypeRow'>
                            <FirstTypeTable
                                sample='Muestra: 18,340'
                                percentage='23%'
                                title='Número de encuestas por mes'
                                data={data}
                            />
                            <FirstTypeTable
                                sample='Muestra: 18,340'
                                percentage='23%'
                                title='Número de encuestas por mes'
                                data={data}
                            />
                        </div>
                    </div>
                </div>
                <div className='StyleContainerRowTrends'>
                    <div className='TypeRowTrends'>
                        <Typography className='TitleTypeTrends'>Tendencias</Typography>
                        <div className='StyleLineTrends'/>
                    </div>
                    <div className='TypeRow'>
                        <SecondTypeTable
                            sample='Muestra: 18,340'
                            percentage='23%'
                            title='Satisfacción general'
                            data={data}
                        />
                        <SecondTypeTable
                            sample='Muestra: 18,340'
                            percentage='23%'
                            title='Satisfacción general'
                            data={data}
                        />
                        <SecondTypeTable
                            sample='Muestra: 18,340'
                            percentage='23%'
                            title='Satisfacción general'
                            data={data}
                        />
                        <SecondTypeTable
                            sample='Muestra: 18,340'
                            percentage='23%'
                            title='Satisfacción general'
                            data={data}
                        />
                    </div>
                </div>
                <div className='StyleContainerRowTrends'>
                    <div className='TypeRowTrends'>
                        <Typography className='TitleTypeTrends'>Estaciones</Typography>
                        <div className='StyleLineTrends'/>
                    </div>
                    <div className='TypeRow'>
                        <div className='GraphMapContainer'></div>
                        <div className='RowTablesTopRating'>
                            <ThirdTypeTable
                                sample='Muestra: 18,340'
                                title='Top 5 Mejores estaciones'
                                data={data}
                                firtsData={data[0]}
                            />
                            <ThirdTypeTable
                                sample='Muestra: 18,340'
                                title='Top 5 Mejores estaciones'
                                data={data}
                                firtsData={data[0]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;