import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import images from '../../assets/images';
import logoIconClientship from '../../assets/svg/LogoClientship.svg';
import logoIconClient from '../../assets/svg/LogoClient.svg';
import './NavBarTop.css';

function NavBarTop() {
    return (
        <nav className="navbar fixed-top navbar-expand-lg text-light background-clientship nav-borders top-navbar">
            <div className="container">
                <a className="navbar-brand" href="/"><img src={logoIconClientship} width="50" alt="Clientship Logo" /></a>
                <a className="navbar-brand sub-brand" href="#"><img src={logoIconClient} width="35" alt="Cliente Logo" /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarScroll">
                    <div className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll"></div>
                    <ul className="navbar-nav my-2 my-lg-0">
                        <li className="nav-item">
                            <a className="nav-link">
                                <img src={images.headerTopIcon} alt="VOC Logo" />
                            </a>
                        </li>
                        <li className="btn-group seccion-item">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Perfil
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li><a className="dropdown-item" href="perfil.html">Usuario</a></li>
                                <li><a className="dropdown-item" href="logout.html">Administrar mi perfil</a></li>
                                <li><a className="dropdown-item" href="logout.html">Opciones</a></li>
                                <li><a className="dropdown-item" href="logout.html">Paleta de colores</a></li>
                                <li><a className="dropdown-item" href="logout.html">Salir</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavBarTop;