import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { 
    Typography,
} from 'antd';
import { CaretUpFilled } from '@ant-design/icons';

import images from '../../assets/images';
import { FirstTypeTableInterface } from './FirstTypeTable.interface';
import './FirstTypeTable.css';

function FirstTypeTable({
    sample,
    percentage,
    title,
    data
}: FirstTypeTableInterface) {
    return(
        <div className='GraphInfoContainer'>
            <div className='SampleInfoStyle'>
                <Typography className='TextSample'>{sample}</Typography>
            </div>
            <div className='PercentageInfo'>
                <CaretUpFilled style={{color: '#C286F1', fontSize: 20}}/>
                <Typography className='TextPercentage'>{percentage}</Typography>
            </div>
            <Typography className='TextNumberSurvey'>{title}</Typography>
            {data.map((isData) => (
                <div className='InfoSurvey' key={isData.id}>
                    <Typography className='RangeText'>{isData.month.substring(0, 3)}</Typography>
                    <Typography className='QuantityText'>{isData.suverey}</Typography>
                    <img src={images.zigzagGreen} className="ZigzagIcon" alt="zigzag-icon" />
                </div>
            ))}
        </div>
    )
}

export default FirstTypeTable;