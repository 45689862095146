import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { 
    Typography,
    Input,
    Button
} from 'antd';

import logoClientship from '../../assets/svg/clientship_blanco.svg';
import logoIconClientship from '../../assets/svg/LogoClientship.svg';
import logoIconClient from '../../assets/svg/LogoClient.svg';
import './Login.css';

import { LoginInterface } from './interfaces';

export default function Login({
    loginAction
}: LoginInterface) {
    return(
        <div className="Container-general">
            <div className="container-fluid login-container">
                <div className="row g-0">
                    <div className="col-12">
                        <img src={logoClientship} className="Image-top" alt="logo-clientship" />
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="Image-left py-5 py-md-2">
                            <div className="Container-rowImage">
                                <img src={logoIconClientship} className="Image-logo" alt="logo-icon-clientship" />
                                <Typography className="Text-logo">+</Typography>
                                <img src={logoIconClient} className="Image-logo" alt="logo-icon-client" />
                            </div>
                            <div className="Container-rowImage">
                                <Typography className="Text-working">working</Typography>
                                <Typography className="Text-together">together</Typography>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 py-5 Container-rowRight">
                        <Typography className="Title">Ingresa a tu cuenta</Typography>
                        <Input className='Input' placeholder='Email'/>
                        <Input className='Input' type='password' placeholder='Contraseña'/>
                        <Button className='Button' onClick={loginAction}>INGRESAR</Button>
                    </div>
                    <div className="col-12">
                        <Typography className="Text-left">Clientship © 2023 Derechos reservados</Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}