import React from "react";
import ReactDOM from "react-dom";

import { 
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";

import { 
    Login,
    Dashboard
} from "../containers";

export function AppRouters() {
    return(
        <Router>
            <Routes>
                <Route path="/" element={<Login/>} />
                <Route path="/dashboard" element={<Dashboard/>} />
            </Routes>
        </Router>
    )
}