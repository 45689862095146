import React from 'react';

import FirstTypeTableUI from './FirstTypeTable';
import { FirstTypeTableInterface } from './FirstTypeTable.interface';

function FirstTypeTable({
    sample,
    percentage,
    title,
    data
}: FirstTypeTableInterface) {
    return <FirstTypeTableUI
        sample={sample}
        percentage={percentage}
        title={title}
        data={data}
    />
}

export default FirstTypeTable;