import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoginUI from './Login';

export default function Login() {
    const navigate = useNavigate();

    const loginAction = () => {
        navigate("/dashboard")
    };

    return <LoginUI
        loginAction={loginAction}
    />
}

